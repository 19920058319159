import React from 'react';
import { Toolbar, Typography, Avatar, Box, AppBar } from '@mui/material';
import imgIcon from '../img/coding.png';

function Header() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                sx={{ alignItems: 'center', backgroundColor: '#003B46' }}
            >
                <Toolbar variant="dense">
                    <Avatar
                        alt="menuIcon"
                        src={imgIcon}
                        sx={{ mr: 2, width: 40, height: 40 }}
                        variant="square"
                    />
                    <Typography
                        variant="h6"
                        color="inherit"
                        component="div"
                        sx={{ fontFamily: 'titre', textAlign: 'center' }}
                    >
                        <h1>Bienvenue sur mon site perso</h1>
                    </Typography>
                    <Avatar
                        alt="menuIcon"
                        src={imgIcon}
                        sx={{ ml: 2, width: 40, height: 40 }}
                        variant="square"
                    />
                </Toolbar>
                <h4 className="prez">
                    Ce site permet d'accèder à plusieurs services que mon
                    raspberry met à disposition:
                </h4>
            </AppBar>
        </Box>
    );
}

export default Header;