import React from 'react';
import { Toolbar, Typography, Avatar, Box, AppBar } from '@mui/material';
import imgIcon from '../img/coding.png';

function Footer() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                sx={{ alignItems: 'center', backgroundColor: '#003B46' }}
            >
                <Toolbar variant="dense">
                    <Avatar
                        alt="menuIcon"
                        src={imgIcon}
                        sx={{ mr: 2, width: 25, height: 25 }}
                        variant="square"
                    />
                    <Typography
                        variant="h6"
                        color="inherit"
                        component="div"
                        sx={{ fontFamily: 'footer', textAlign: 'center' }}
                    >
                        <h4>Copyright &copy; BSI {new Date().getFullYear()} - {process.env.REACT_APP_PLATEFORME}</h4>
                    </Typography>
                    <Avatar
                        alt="menuIcon"
                        src={imgIcon}
                        sx={{ ml: 2, width: 25, height: 25 }}
                        variant="square"
                    />
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Footer;