import React, {useEffect, useState} from 'react';
import {Grid, Paper} from '@mui/material';
import { styled } from '@mui/material/styles';
import {Bouton} from "../models/Bouton";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    display: 'flex',
    flexFlow: 'row wrap',
    width: 'auto',
    height: 'auto',
    textAlign: 'center',
    backgroundColor: '#c4dfe6',
}));

function Section() {
    const [bouttons, setBouttons] = useState<Bouton[] | null>(null);
    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL+'/api/bouton')
            .then(response => response.json())
            .then(data => setBouttons(data));
    }, [])

    return (
        <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 5 }}
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            sx={{ padding: 2 }}
        >
            {bouttons !== null && bouttons.map((monItem: Bouton) => (
                <Grid
                    key={monItem.id}
                    item
                    xl={1}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                >
                    <Item className="monItem">
                        <a
                            href={monItem.url}
                            target="_blank"
                            rel="noreferrer"
                            style={{ boxSizing: 'border-box', width: '100%' }}
                        >
                            <img
                                src={`data:image/jpeg;base64,${monItem.image}`}
                                className="image img-fluid"
                                alt={monItem.libelle}
                            />
                        </a>
                    </Item>
                </Grid>
            ))}
        </Grid>
    );
}

export default Section;