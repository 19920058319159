import './App.css';
import React from 'react';
import { Grid } from '@mui/material';
import Header from './components/Header'
import Section from './components/Section'
import Footer from './components/Footer'

function App() {
  return (
      <Grid
          container
          direction="column"
          spacing={0}
          className="colorBack"
          style={{ minHeight: '100vh' }}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto',
            gap: 0,
            gridTemplateRows: 'max-content auto max-content',
            gridTemplateAreas: `"header"
                          "article"
                          "footer"`,
          }}
      >
        <Grid className="colorHead" sx={{ gridArea: 'header' }}>
          <Header />
        </Grid>
        <Grid className="colorBack" sx={{ gridArea: 'article' }}>
          <Section />
        </Grid>
        <Grid className="colorFoot" sx={{ gridArea: 'footer' }}>
          <Footer />
        </Grid>
      </Grid>
  );
}

export default App;
